const redirects = {
   'url1' : { landing_page : 'https://soltechenergy.com/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=nyhetsbrevsnamn', name: "Huvudsida" },

   'url2' : { landing_page : 'https://soltechenergy.com/notis/nya-lagkrav-pa-solceller-vi-reder-ut-vad-som-galler/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=nyhetsbrevsnamn', name: "Nya lagkrav på solceller" },

   'url3' : { landing_page : 'https://soltechenergy.com/notis/helhetslosningar-for-en-energismart-fastighet/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=nyhetsbrevsnamn', name: "Helhetslösningar för en energismart fastighet" },

   'url4' : { landing_page : 'https://soltechenergy.com/notis/fasader-for-en-ljusare-framtid/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=nyhetsbrevsnamn', name: "Solcellsfasader för en ljusare framtid" },

   'url5' : { landing_page : 'https://soltechenergy.com/notis/laddningslosningar-for-alla-behov/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=nyhetsbrevsnamn', name: "Laddningslösningar för alla behov" },

   'url6' : { landing_page : 'https://soltechenergy.com/kontaktformular/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=nyhetsbrevsnamn', name: "Kontakta oss" },
};

export default redirects